/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TextField,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { formatDate } from '../../../utils/date';
import thousandSeparator from '../../../utils/thousandSeparator';

import useStyles from './styles';

function Order({ className, order, ...rest }) {
  const classes = useStyles();
  const [totalCargo, setTotalCargo] = useState(0);
  const [totalNeto, setTotalNeto] = useState(0);
  const [totalBruto, setTotalBruto] = useState(0);
  const [search, setSearch] = useState('');
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const cargo = order?.products?.reduce((acc, p) => acc + p.orderProduct.cargo, 0);
    const neto = order?.products?.reduce((acc, p) => acc + p.orderProduct.finalPrice, 0);
    const bruto = order?.products?.reduce((acc, p) => acc + p.orderProduct.finalPrice
    + p.orderProduct.effectiveIla
    + p.orderProduct.effectiveIva, 0);
    setTotalCargo(cargo);
    setTotalNeto(neto - cargo);
    setTotalBruto(bruto);
    setFilteredProducts(order?.products);
  }, [order]);

  useEffect(() => {
    if (search) {
      setFilteredProducts(order?.products?.filter((p) => p.sapId.includes(search)
        || p.description.toLowerCase().includes(search.toLowerCase())));
    } else {
      setFilteredProducts(order?.products);
    }
  }, [search]);

  if (Object.keys(order).length === 0) {
    return null;
  }

  const discountCalculation = (product) => {
    const {
      discount_1, discount_2, discount_3, discount_4, discount_5,
    } = product?.orderProduct;

    let totalDiscountFactor = 1;

    if (discount_1) {
      totalDiscountFactor *= (1 - discount_1 / 100);
    }
    if (discount_2) {
      totalDiscountFactor *= (1 - discount_2 / 100);
    }
    if (discount_3) {
      totalDiscountFactor *= (1 - discount_3 / 100);
    }
    if (discount_4) {
      totalDiscountFactor *= (1 - discount_4 / 100);
    }
    if (discount_5) {
      totalDiscountFactor *= (1 - discount_5 / 100);
    }

    const totalDiscountPercentage = (1 - totalDiscountFactor) * 100;

    return Number(totalDiscountPercentage.toFixed(2));
  };

  const sumTotals = (product) => product.orderProduct.finalPrice
    + product.orderProduct.effectiveIla
    + product.orderProduct.effectiveIva;

  const calculateRappel = (product) => {
    let rappel = 0;
    order.invoices.forEach((invoice) => {
      const invoiceProduct = invoice
        .invoiceProducts.find((ip) => ip.product_id === product.id);
      if (invoiceProduct) {
        rappel = invoiceProduct.rapel;
      }
    });
    return rappel;
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {/* Cabecera con información general del pedido */}
      <Card className={classes.headerCard}>
        <Box p={2}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Pedido SAP</TableCell>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Fecha creación</TableCell>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Fecha despacho</TableCell>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Cliente</TableCell>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Dirección despacho</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{order.sapId}</TableCell>
                <TableCell>{formatDate(order.createdAt)}</TableCell>
                <TableCell>{formatDate(order.date)}</TableCell>
                <TableCell>{order.client.name}</TableCell>
                <TableCell>
                  {`${order.clientDirection.address}, ${order.clientDirection.commune.name}`}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Card>
      <Card className={classes.costCard}>
        <Box p={2}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Costo flete</TableCell>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Total neto</TableCell>
                <TableCell style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)' }}>Total bruto</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>$ {thousandSeparator(totalCargo, '.')}</TableCell>
                <TableCell>$ {thousandSeparator(totalNeto, '.')}</TableCell>
                <TableCell>$ {thousandSeparator(totalBruto, '.')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Card>
      <Card>
      <Box my={2}>
        <TextField
          fullWidth
          placeholder="Buscar"
          variant="outlined"
          className={classes.searchField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Código producto</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Precio Neto</TableCell>
                  <TableCell>Cantidad</TableCell>
                  <TableCell>UMV</TableCell>
                  <TableCell>Descuento</TableCell>
                  <TableCell>Rapel</TableCell>
                  <TableCell>Precio Final</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProducts?.length > 0 ? filteredProducts.map((product) => (
                  <TableRow key={product.id}>
                    <TableCell>{product.sapId}</TableCell>
                    <TableCell>{product.description}</TableCell>
                    <TableCell>$ {thousandSeparator(product?.orderProduct?.finalPrice, '.')}</TableCell>
                    <TableCell>{product?.orderProduct?.amount}</TableCell>
                    <TableCell>{product?.orderProduct?.unitMeasurement}</TableCell>
                    <TableCell>{discountCalculation(product)}%</TableCell>
                    <TableCell>${calculateRappel(product)}</TableCell>
                    <TableCell>$ {thousandSeparator(sumTotals(product), '.')}</TableCell>
                  </TableRow>
                )) : (
                  <TableRow>
                    <TableCell colSpan={9} style={{ textAlign: 'center' }}>No se encontraron productos</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
}

Order.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired,
};

Order.defaultProps = {
  order: {},
};

export default Order;
