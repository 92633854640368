import axios from '../../utils/axios';

const getClientDetailViewData = async (clientId) => {
  const response = await axios.get(`/api/client/detail-view/${clientId}`);

  return response.data;
};

const getClientsPerPage = async ({
  page,
  limit,
  search,
  status,
  paymentConditions,
  sellerId,
  sortBy,
  sortDirection,
}) => {
  const response = await axios.get(
    `/api/clients?page=${page}&limit=${limit}&search=${search}&status=${status}&paymentConditions=${paymentConditions}&sellerId=${sellerId}&sortBy=${sortBy}&sortDirection=${sortDirection}`,
  );

  return response.data;
};

const getAllClientsPurchases = async (clientIds) => {
  try {
    const response = await axios.post('/api/clients/purchases', {
      clientIds,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export { getClientDetailViewData, getClientsPerPage, getAllClientsPurchases };
