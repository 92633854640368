import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useStyles from './styles';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import SellerContent from './SellerContent';
import getObjetives from '../../../requests/api/objetive';
import { getAverageMargin, getSellerDetailViewData } from '../../../requests/api/seller';

function DashboardView() {
  const classes = useStyles();
  const user = useSelector((state) => state.account.user);
  const [objetive, setObjetive] = useState({});
  const [chartData, setChartData] = useState({});
  const [averageMargin, setAverageMargin] = useState([]);
  const [averageMarginLastYear, setAverageMarginLastYear] = useState([]);
  const [goals, setGoals] = useState([]);
  const getSeller = async () => {
    const data = await getSellerDetailViewData(user?.seller?.id);
    setChartData(data.seller);
    setGoals(data.seller.thisYearObjectives);
  };

  const getMetrics = async () => {
    const data = await getObjetives(user?.seller?.id);
    setObjetive(data.objective);
  };

  const getAverageMarginData = async () => {
    const data = await getAverageMargin(user?.seller?.id, 2025);
    setAverageMargin(data.averageMargin || []);
  };

  const getAverageMarginDataLastYear = async () => {
    const data = await getAverageMargin(user?.seller?.id, 2024);
    setAverageMarginLastYear(data.averageMargin || []);
  };

  useEffect(() => {
    getMetrics();
    getSeller();
    getAverageMarginData();
    getAverageMarginDataLastYear();
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Header />
        <SellerContent
         objetive={objetive}
         chartData={chartData}
         goals={goals}
         averageMarginData={averageMargin}
         averageMarginDataLastYear={averageMarginLastYear} />
      </Container>
    </Page>
  );
}

export default withRouter(DashboardView);
