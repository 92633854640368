import React from 'react';
import PropTypes from 'prop-types';
import { Card as MuiCard, CardContent } from '@mui/material';

const Card = ({
  children,
  width = 278,
  height = 165,
  maxWidth,
  maxHeight,
  widthPercentage,
  heightPercentage,
}) => {
  const dimensionStyles = {
    width: widthPercentage ? `${widthPercentage}%` : width,
    height: heightPercentage ? `${heightPercentage}%` : height,
    maxWidth: maxWidth || 'none',
    maxHeight: maxHeight || 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 2,
  };

  const contentStyles = {
    padding: '16px',
    height: '100%',
    '&:last-child': {
      paddingBottom: '16px',
    },
  };

  return (
    <MuiCard sx={dimensionStyles}>
      <CardContent sx={contentStyles}>
        {children}
      </CardContent>
    </MuiCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  maxWidth: PropTypes.number,
  maxHeight: PropTypes.number,
  widthPercentage: PropTypes.number,
  heightPercentage: PropTypes.number,
};

export default Card;
