import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    padding: theme.spacing(3),
    '& .MuiTableCell-head': {
      backgroundColor: theme.palette.background.default,
      fontWeight: 700,
    },
  },
  headerCard: {
    marginBottom: theme.spacing(2),
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  costCard: {
    marginBottom: theme.spacing(2),
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  searchField: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    maxWidth: 265,
    height: 30,
  },
  tableRow: {
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  expandButton: {
    padding: theme.spacing(1),
  },
  detailsRow: {
    backgroundColor: theme.palette.background.default,
  },
  shoppingBagIcon: {
    marginRight: theme.spacing(1),
    fontSize: 24,
    color: '#62A606',
  },
  headerTitle: {
    fontWeight: 700,
    color: '#1A202C',
    fontSize: 24,
  },
}));

export default useStyles;
