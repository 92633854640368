import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Divider } from '@mui/material';
import Card from './Card';

const Metric = ({
  title,
  amount,
  amountSuffix,
  percentage,
  percentageText,
  hidePrefix,
}) => (
  <Card widthPercentage={100} maxWidth={278} maxHeight={165}>
    <Typography
      variant="subtitle1"
      color="text.secondary"
      gutterBottom
    >
      {title}
    </Typography>

    <Typography
      variant="h4"
      component="div"
      sx={{
        fontWeight: 'bold',
        my: 2,
      }}
    >
      {!hidePrefix && '$'}
      {new Intl.NumberFormat('es-AR').format(amount)}
      {amountSuffix && ` ${amountSuffix}`}
    </Typography>

    <Divider sx={{ my: 1 }} />

    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography
        variant="body2"
        sx={{
          color: percentage >= 0 ? 'success.main' : 'error.main',
        }}
      >
        {percentage}% {percentageText}
      </Typography>
    </Box>
  </Card>
);

Metric.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number,
  amountSuffix: PropTypes.string,
  percentage: PropTypes.string,
  percentageText: PropTypes.string,
  hidePrefix: PropTypes.bool,
};

Metric.defaultProps = {
  amountSuffix: '',
  hidePrefix: false,
};

export default Metric;
