import React from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

function Client({ client }) {
  if (Object.keys(client).length === 0) {
    return null;
  }

  return (
    <Card>
      <PerfectScrollbar>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Table stickyHeader={true}>
              <TableHead>
                <TableRow>
                  <TableCell>Rut</TableCell>
                  <TableCell>Razón social</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Condicion de pago</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover key={client.id}>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.rut}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.name}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.status}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography color="inherit" variant="h6">
                      {client?.paymentCondition?.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </PerfectScrollbar>
    </Card>
  );
}

Client.propTypes = {
  className: PropTypes.string,
  client: PropTypes.object.isRequired,
};

Client.defaultProps = {
  client: {},
};

export default Client;
