import React, { useEffect, useState } from 'react';
import { Container } from '@mui/material';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Page from '../../../components/Layout/Page';
import Header from './Header';
import Seller from './Seller';
import { getSellerDetailViewData } from '../../../requests/api/seller';
import handleApiResponse from '../../../utils/handleApiResponse';
import useStyles from './styles';

function SellerDetailView(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const account = useSelector((state) => state.account);

  const [seller, setSeller] = useState({});
  let sellerId;

  if (props.match.params.id) {
    sellerId = props.match.params.id;
  } else {
    sellerId = account.user.seller.id;
  }

  useEffect(() => {
    const getSeller = async () => {
      try {
        const response = await getSellerDetailViewData(sellerId);
        setSeller(response.seller);
      } catch (err) {
        handleApiResponse(enqueueSnackbar, err, false);
      }
    };

    getSeller();
    // eslint-disable-next-line
  }, [props]);

  return (
    <Page className={classes.root} title="Detalle del vendedor">
      <Container maxWidth={false}>
        <Header />
        <div>
          <Seller seller={seller} />
        </div>
      </Container>
    </Page>
  );
}

SellerDetailView.propTypes = {
  match: PropTypes.any,
};

export default withRouter(SellerDetailView);
