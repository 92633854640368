import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';

import Metric from './Metric';
import DataChart from './DataChart';
import useStyles from './styles';

const SellerContent = ({
  objetive,
  chartData,
  averageMarginData,
  goals,
  averageMarginDataLastYear,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  useEffect(() => {
    if (chartData?.monthsOfThisYear) {
      setIsLoading(false);
    }
  }, [chartData]);

  const chartObjetives = {
    series: [{
      name: 'Venta',
      color: '#67B231',
      data: chartData?.monthsOfThisYear?.map((item) => item.totalSell) || [],
    }],
    text: 'Meta vs Venta',
    meta: goals,
    months: chartData?.monthsOfThisYear?.map((item) => item.month) || [],
    goals,
    averageMarginData,
    data: chartData?.monthsOfThisYear || [],
  };

  const chartComparatives = {
    series: [
      {
        name: '2024',
        color: '#67B231',
        data: chartData?.monthsOfLastYear?.map((item) => item.totalSell) || [],
      },
      {
        name: '2025',
        color: '#24335E',
        data: chartData?.monthsOfThisYear?.map((item) => item.totalSell) || [],
      }],
    text: 'Comparativo de venta vs año anterior',
    months: chartData?.monthsOfThisYear?.map((item) => item.month) || [],
    monthsLastYear: chartData?.monthsOfLastYear?.map((item) => item.month) || [],
    averageMarginData,
    data: chartData?.monthsOfThisYear?.map((item) => {
      const lastYearMonth = chartData?.monthsOfLastYear
        ?.find((month) => month.month === item.month);
      return {
        ...item,
        lastYearMonth,
      };
    }) || [],
    averageMarginDataLastYear,
  };

  const renderMetricOrSkeleton = (props) => {
    if (isLoading) {
      return (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={120}
          animation="wave"
        />
      );
    }
    return <Metric {...props} />;
  };

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={3}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Ventas mensuales',
            amount: objetive?.sells,
            percentage: objetive?.profit?.toFixed(2),
            percentageText: 'margen',
          })}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Meta mensual',
            amount: objetive?.amount,
            percentage: ((objetive?.sells * 100) / objetive?.amount).toFixed(2),
            percentageText: 'cumplimiento',
          })}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Cobertura mensual',
            amount: objetive?.uniqueClients?.length,
            amountSuffix: `de ${objetive?.totalClients}`,
            percentage: ((objetive?.uniqueClients
              ?.length * 100) / objetive?.totalClients).toFixed(2),
            percentageText: 'cumplimiento',
            hidePrefix: true,
          })}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {renderMetricOrSkeleton({
            title: 'Cierre de proyectado',
            amount: objetive?.projectedSells,
            percentage: ((objetive?.sells * 100) / objetive?.projectedSells).toFixed(2),
            percentageText: 'cumplimiento',
          })}
        </Grid>

        <Grid item xs={12} className={classes.chartContainer}>
          <DataChart {...chartObjetives} />
        </Grid>

        <Grid item xs={12} className={classes.chartContainer}>
          <DataChart {...chartComparatives} />
        </Grid>
      </Grid>
    </div>
  );
};

SellerContent.propTypes = {
  objetive: PropTypes.object.isRequired,
  chartData: PropTypes.object.isRequired,
  averageMarginData: PropTypes.array.isRequired,
  goals: PropTypes.array.isRequired,
  averageMarginDataLastYear: PropTypes.array.isRequired,
};

export default SellerContent;
