import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';

import useStyles from './styles';

function Header({ className, ...rest }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="space-between"
      spacing={3}
      {...rest}
    >
      <Grid item>
        <div className={classes.headerTitleDiv}>
          <Home style={{ color: 'green', marginRight: '8px' }} />
          <Typography
            className={classes.headerTitle}
          >
            Dashboard
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  className: PropTypes.string,
};

export default Header;
