const handleApiResponse = (
  enqueueSnackbar,
  response,
  successSnackbar,
  variant = null,
  action = null,
) => {
  if (successSnackbar) {
    enqueueSnackbar(response.message, {
      variant: variant || 'success',
      action,
      autoHideDuration: 3000,
    });
  } else {
    enqueueSnackbar(response.response?.data?.errorMessage || response.message, {
      variant: variant || 'error',
      action,
      autoHideDuration: 3000,
    });
  }
};

export default handleApiResponse;
