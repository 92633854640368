import axios from '../../utils/axios';

const getOrderById = async (orderId) => {
  const response = await axios.get(`/api/order/${orderId}`);

  return response.data;
};

const getOrdersPerPage = async ({
  page,
  limit,
  search,
  status,
  startDate,
  endDate,
  sellerId,
  invoices,
  clientId,
}) => {
  const response = await axios.get(
    `/api/orders?page=${page}&limit=${limit}&search=${search}&status=${status}&startDate=${startDate}&endDate=${endDate}&sellerId=${sellerId}&invoices=${invoices}&clientId=${clientId}`,
  );

  return response.data;
};

export { getOrderById, getOrdersPerPage };
