import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  gridContainer: {
    '& .MuiGrid-item': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  chartContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    '& > div': {
      margin: '0 auto',
      width: '100%',
      maxWidth: '1172px',
      '& .highcharts-container': {
        margin: '0 auto',
      },
    },
  },
}));

export default useStyles;
