import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  InputAdornment,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link as RouterLink } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers';

import { Search as SearchIcon } from 'react-feather';
import useStyles from './styles';

import { formatDate } from '../../../utils/date';

function Results({
  className,
  orders,
  page,
  setPage,
  limit,
  setLimit,
  filters,
  setFilters,
  setSendQuery,
  totalOrders,
  sellerRole,
  clients,
  onLoadMoreClients,
  loadingClients,
  hasMoreClients,
  onClientSearch,
  clientSearch,
  loadingOrders,
  ...rest
}) {
  const classes = useStyles();
  const [currentOrders, setCurrentOrders] = useState(orders);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [selectedClient, setSelectedClient] = useState('');

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    setFilters({
      ...filters,
      clientId,
    });
    // setSendQuery((prev) => !prev);
  };

  const handleDateFromChange = (newValue) => {
    setDateFrom(newValue);
    setFilters({
      ...filters,
      startDate: newValue ? newValue.toISOString().split('T')[0] : '',
    });
  };

  const handleDateToChange = (newValue) => {
    setDateTo(newValue);
    setFilters({
      ...filters,
      endDate: newValue ? newValue.toISOString().split('T')[0] : '',
    });
  };

  useEffect(() => {
    setCurrentOrders(orders);
  }, [orders]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handleFilterChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  // const handleDownload = async () => {
  //   try {
  //     const response = await axios.get('/api/orders/export', {
  //       params: {
  //         page: 0,
  //         limit: 10000,
  //         search: filters.search,
  //         status: filters.status.map((e) => e.value).join(','),
  //         startDate: filters.startDate,
  //         endDate: filters.endDate,
  //         sellerId: account?.user?.seller?.id || 0,
  //       },
  //     });

  //     if (response.data.files.length) {
  //       response.data.files.forEach((file) => {
  //         const uri = `data:application/vnd.openxmlformats-officedocument
  //          .spreadsheetml.sheet;base64,${file.file}`;
  //         const downloadLink = document.createElement('a');
  //         downloadLink.href = uri;
  //         downloadLink.download = file.fileName;
  //         document.body.appendChild(downloadLink);
  //         downloadLink.click();
  //         document.body.removeChild(downloadLink);
  //       });
  //     } else {
  //       enqueueSnackbar('No hay archivos para descargar', { variant: 'warning' });
  //     }
  //   } catch (error) {
  //     enqueueSnackbar('Error al descargar los pedidos', { variant: 'error' });
  //   }
  // };

  const deliveryStatus = (order) => {
    if (order.missingProducts === 1) {
      return (
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: 'yellow',
          }}
        />
      );
    } if (order.missingProducts === 2) {
      return (
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: 'red',
          }}
        />
      );
    }

    return (
        <Box
        sx={{
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: 'green',
        }}
      />
    );
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid
        container
        alignItems="center"
        spacing={2}
        p={2}
      >
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel>Cliente</InputLabel>
            <Select
              value={selectedClient}
              onChange={handleClientChange}
              label="Cliente"
              onClose={() => onClientSearch('')}
              MenuProps={{
                PaperProps: {
                  onScroll: (event) => {
                    const listboxNode = event.target;
                    if (
                      listboxNode.scrollTop
                      + listboxNode.clientHeight >= listboxNode.scrollHeight - 50
                      && hasMoreClients
                      && !loadingClients
                    ) {
                      onLoadMoreClients();
                    }
                  },
                  style: { maxHeight: 300 },
                },
              }}
            >
              <MenuItem>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Buscar cliente..."
                  value={clientSearch}
                  onChange={(e) => {
                    e.stopPropagation();
                    onClientSearch(e.target.value);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  variant="outlined"
                />
              </MenuItem>
              <MenuItem value="">
                <em>Todos</em>
              </MenuItem>
              {clients.map((client, index) => (
                <MenuItem key={index} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
              {loadingClients && (
                <MenuItem disabled>
                  <Box display="flex" justifyContent="center" width="100%" py={1}>
                    <CircularProgress size={20} />
                  </Box>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2}>
          <DatePicker
            label="Desde"
            value={dateFrom}
            onChange={handleDateFromChange}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <DatePicker
            label="Hasta"
            value={dateTo}
            onChange={handleDateToChange}
            slotProps={{
              textField: {
                size: 'small',
                fullWidth: true,
                variant: 'outlined',
              },
            }}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            style={{
              backgroundColor: '#8BC34A',
              color: 'white',
              textTransform: 'none',
            }}
            onClick={() => setSendQuery((prev) => !prev)}
          >
            Filtrar
          </Button>
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            size="small"
            className={classes.queryField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              e.stopPropagation();
              handleFilterChange(e.target.value, 'search');
              if (e.target.value.length > 2 || e.target.value.length === 0) {
                setSendQuery((prev) => !prev);
              }
            }}
            placeholder="Buscar N° pedido"
            value={filters.search}
            variant="outlined"
          />
        </Grid>
      </Grid>

      { !loadingOrders ? (
        <>
        <PerfectScrollbar>
          <Box minWidth={700}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nº pedido</TableCell>
                  <TableCell>Fecha pedido</TableCell>
                  <TableCell>RUT</TableCell>
                  <TableCell>Nombre cliente</TableCell>
                  <TableCell>Dirección despacho</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Nº factura</TableCell>
                  <TableCell>Entregado</TableCell>
                  <TableCell>Ver más</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentOrders.map((order) => (
                  <TableRow hover key={order.id}>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {order.sap_id}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                      {formatDate(order.date)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {order.client.rut}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                      {order.client.name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {order.clientDirection.address}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {order.status}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography color="inherit" variant="h6">
                        {order.invoices.map((invoice) => invoice.reference).join(', ')}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {deliveryStatus(order)}
                      </div>
                    </TableCell>
                    <TableCell>
                    <IconButton
                        component={RouterLink}
                        to={`/vendedor/pedidos/${order.id}/detalle`}
                        size="large"
                      >
                        <SvgIcon fontSize="small">
                          <MoreHorizIcon />
                        </SvgIcon>
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={totalOrders}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Filas por página"
        />
        </>
      ) : <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>}
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  orders: PropTypes.array,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSendQuery: PropTypes.func.isRequired,
  totalOrders: PropTypes.number.isRequired,
  sellerRole: PropTypes.string.isRequired,
  clients: PropTypes.array.isRequired,
  onLoadMoreClients: PropTypes.func.isRequired,
  loadingClients: PropTypes.bool.isRequired,
  hasMoreClients: PropTypes.bool.isRequired,
  onClientSearch: PropTypes.func.isRequired,
  clientSearch: PropTypes.string.isRequired,
  loadingOrders: PropTypes.bool.isRequired,
};

Results.defaultProps = {
  orders: [],
};

export default Results;
