import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { format } from 'rut.js';
import moment from 'moment';
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link as RouterLink } from 'react-router-dom';
import SortableTable from '../../../components/Table/SortableTable';
import thousandSeparator from '../../../utils/thousandSeparator';
import { ADMIN, SUPER_ADMIN } from '../../../constants/userRoles';
import useStyles from './styles';

function Results({
  className,
  clients,
  page,
  setPage,
  limit,
  setLimit,
  filters,
  setFilters,
  setSendQuery,
  totalClients,
  sellerRole,
  ...rest
}) {
  const classes = useStyles();

  const columns = [
    {
      id: 'rut',
      label: 'Rut',
      sortable: true,
      render: (row) => format(row.rut),
    },
    {
      id: 'name',
      label: 'Nombre cliente',
      sortable: true,
    },
    {
      id: 'purchases.totalAmount',
      label: 'Venta acumulada',
      sortable: true,
      render: (row) => `$${thousandSeparator(row?.purchases?.totalAmount, '.')}` ?? 0,
    },
    {
      id: 'purchases.lastOrderDate',
      label: 'Fecha último pedido',
      sortable: true,
      render: (row) => (row?.purchases?.lastOrderDate
        ? moment(row.purchases.lastOrderDate).format('DD/MM/YYYY')
        : 'N/A'),
    },
    {
      id: 'paymentCondition.name',
      label: 'Condición de pago',
      sortable: true,
      render: (row) => row?.paymentCondition?.name,
    },
    {
      id: 'actions',
      label: '',
      align: 'right',
      render: (row) => (
        <IconButton
          disabled={!row?.purchases?.totalAmount}
          component={RouterLink}
          to={[ADMIN, SUPER_ADMIN].includes(sellerRole)
            ? `/administracion/clientes/${row.id}/detalle`
            : `/vendedor/clientes/${row.id}/detalle`}
          size="large"
        >
          <SvgIcon fontSize="small">
            <MoreHorizIcon />
          </SvgIcon>
        </IconButton>
      ),
    },
  ];

  const handleFilterChange = (value, name) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const handleSort = (columnId, direction) => {
    setFilters({
      ...filters,
      sortBy: columnId,
      sortDirection: direction,
    });
    setSendQuery((prev) => !prev);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <Grid container alignItems="center" justifyContent="flex-end" p={2} spacing={1}>
        <Grid
          container
          alignItems="center"
          justifyContent="flex-end"
          spacing={1}
          className={classes.searchGrid}
        >
          <Grid item>
            <TextField
              className={classes.queryField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                handleFilterChange(e.target.value, 'search');
                if (e.target.value.length > 2 || e.target.value.length === 0) {
                  setSendQuery((prev) => !prev);
                }
              }}
              placeholder="Rut o Razón Social"
              value={filters.search}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Grid>
      <SortableTable
        columns={columns}
        data={clients}
        page={page}
        setPage={setPage}
        limit={limit}
        setLimit={setLimit}
        total={totalClients}
        sortBy={filters.sortBy}
        sortDirection={filters.sortDirection}
        onSort={handleSort}
      />
    </Card>
  );
}

Results.propTypes = {
  className: PropTypes.string,
  clients: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setSendQuery: PropTypes.func.isRequired,
  totalClients: PropTypes.number.isRequired,
  sellerRole: PropTypes.string.isRequired,
};

export default Results;
