import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import { ArrowUpward, ArrowDownward } from '@mui/icons-material';
import PerfectScrollbar from 'react-perfect-scrollbar';

function SortableTable({
  columns,
  data,
  page,
  setPage,
  limit,
  setLimit,
  total,
  sortBy,
  sortDirection = 'desc',
  onSort,
  loading,
  emptyMessage = 'No hay datos disponibles',
}) {
  const handleSort = (columnId) => {
    if (sortBy !== columnId) {
      onSort(columnId, 'desc');
      return;
    }

    const newDirection = sortDirection === 'desc' ? 'asc' : 'desc';
    onSort(columnId, newDirection);
  };

  const renderSortIcon = (column) => {
    if (!column.sortable) return null;

    if (sortBy !== column.id) {
      return (
        <Box component="span" sx={{ opacity: 0.5, ml: 1 }}>
          <ArrowDownward fontSize="small" />
        </Box>
      );
    }

    return (
      <Box component="span" sx={{ ml: 1 }}>
        {sortDirection === 'asc' ? (
          <ArrowUpward fontSize="small" />
        ) : (
          <ArrowDownward fontSize="small" />
        )}
      </Box>
    );
  };

  return loading ? (<Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
        <CircularProgress />
      </Box>) : (
        <>
        <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align || 'left'}
                    onClick={() => column.sortable && handleSort(column.id)}
                    style={{
                      cursor: column.sortable ? 'pointer' : 'default',
                      userSelect: 'none',
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle2" component="span">
                        {column.label}
                      </Typography>
                      {renderSortIcon(column)}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length > 0 ? (
                data.map((row) => (
                  <TableRow hover key={row.id}>
                    {columns.map((column) => (
                      <TableCell key={`${row.id}-${column.id}`} align={column.align || 'left'}>
                        {column.render ? column.render(row) : row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    {emptyMessage}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={total}
        onPageChange={(e, newPage) => setPage(newPage)}
        onRowsPerPageChange={(e) => setLimit(parseInt(e.target.value, 10))}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Filas por página"
      />
        </>);
}

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      sortable: PropTypes.bool,
      align: PropTypes.oneOf(['left', 'center', 'right']),
      render: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  setLimit: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  sortBy: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  onSort: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  emptyMessage: PropTypes.string,
};

SortableTable.defaultProps = {
  sortDirection: 'desc',
  loading: false,
  emptyMessage: 'No hay datos disponibles',
};

export default SortableTable;
